import { getAllSavedAcqChannels } from '@ipr-marsproxies/shared/tracking'
import Cookies from 'js-cookie'

export const setupCrossDomainTrackingLinks = () => {
	const getEncodedData = () => {
		const channels = getAllSavedAcqChannels()

		const data = {
			gclid: Cookies.get('gclid'),
			acquisitionChannels: channels,
		}

		const isEmpty = Object.values(data).every((value) => !value)

		if (isEmpty) {
			return ''
		}

		const dataJson = JSON.stringify(data)

		// Input can have Unicode characters, so before encoding it to Base64 we encode it to UTF-8
		return window.btoa(encodeURIComponent(dataJson))
	}

	const isDashboardUrl = (url = '') => {
		return url.startsWith(import.meta.env.PUBLIC_DASHBOARD_URL)
	}

	const updateDashboardUrl = (link: HTMLAnchorElement, encodedData: string) => {
		const url = new URL(link.href)

		url.searchParams.set('locale', 'zh-CN')

		if (encodedData) {
			// MARS + Cookies
			url.searchParams.set('_marsc', encodedData)
		}

		link.href = url.toString()
	}

	const updateAllLinks = () => {
		const links = [
			...document.querySelectorAll('a[href]'),
		] as HTMLAnchorElement[]
		const encodedData = getEncodedData()

		for (const link of links) {
			if (!isDashboardUrl(link.href)) {
				continue
			}

			updateDashboardUrl(link, encodedData)
		}
	}

	// Replace all links on load in case user decides to open in new tab
	updateAllLinks()

	// Replace all links on click, because links can be added dynamically
	window.addEventListener(
		'click',
		(e: MouseEvent) => {
			const path = e.composedPath()

			const link = path.find((el) => {
				if (
					el instanceof HTMLAnchorElement &&
					el.href?.startsWith(import.meta.env.PUBLIC_DASHBOARD_URL)
				) {
					return true
				}
				return false
			})

			if (link) {
				const encodedData = getEncodedData()

				updateDashboardUrl(link as HTMLAnchorElement, encodedData)
			}
		},
		// This is needed to make sure that
		// this event listener is called before
		// actual navigation happens
		{ capture: true },
	)
}
