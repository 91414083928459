import { GrowthBook } from '@growthbook/growthbook'
import invariant from 'tiny-invariant'
import { promiseWithResolvers } from './utils'

type AppFeatures = {
	'feature-flag-example': boolean
}

const createGrowthBook = () => {
	invariant(window, 'GrowthBook can only be used in the browser')

	const gb = new GrowthBook<AppFeatures>({
		apiHost: 'https://cdn.growthbook.io',
		clientKey: import.meta.env.PUBLIC_GROWTHBOOK_CLIENT_KEY,
		enableDevMode: !!import.meta.env.PUBLIC_GROWTHBOOK_DEV_MODE,
		subscribeToChanges: true,
		trackingCallback: (experiment, result) => {
			const attrs = gb.getAttributes() as Record<string, string>

			gtag('event', 'experiment_viewed', {
				event_category: 'experiment',
				experiment_id: experiment.key,
				variation_id: result.variationId,
				gb_device_id: attrs.marsDeviceId,
			})

			window.clarity?.('set', 'feature_name', result.featureId)
			window.clarity?.(
				'set',
				'feature_variant',
				`${result.featureId} - ${result.variationId}`,
			)
		},
	})

	// Ensure that before places where we need to use GrowthBook,
	// we have the attributes set
	const pendingAttributes = promiseWithResolvers()

	return {
		loadGrowthBook: async () => {
			await Promise.allSettled([gb.init(), pendingAttributes.promise])

			return gb
		},
		setGrowthbookAttributes: (attributes: Record<string, unknown>) => {
			gb.setAttributes(attributes)
			pendingAttributes.resolve()
		},
	}
}

const { loadGrowthBook, setGrowthbookAttributes } = createGrowthBook()

export { loadGrowthBook, setGrowthbookAttributes }
