export interface AcqReferralData {
	referral: string
	referralUrl?: string
}

export type AcqSourceReferral = 'referral'
export type AcqSourceImpact = 'impact'

export const countReferral = async (
	referral: string,
	referralUrl?: string,
	impact?: boolean,
) => {
	const url = new URL('api/referral', import.meta.env.PUBLIC_DASHBOARD_API_URL)

	await fetch(url, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			referrer: referral,
			referrer_url: referralUrl,
			from_impact: impact,
		}),
	})
}
