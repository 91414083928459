import {
	type SaveAcqChannelOptions,
	getAcqChannel,
	saveAcqChannel,
} from './acquisition'

// Only add the most popular domains
const googleSearchDomains = [
	'google.com',
	'google.co.uk',
	'google.ca',
	'google.com.au',
	'google.com.br',
	'google.co.in',
	'google.co.jp',
	'google.de',
	'google.fr',
	'google.ru',
	'google.es',
	'google.it',
	'google.com.mx',
]

const bingSearchDomains = ['bing.com', 'bing.co.uk', 'bing.ca', 'bing.com.au']

export type AcqSourceSearch = 'organic-search'
export type AcqSourceReferrer = 'referrer'

export interface AcqSearchData {
	engine: 'google' | 'bing'
}

export interface AcqReferrerData {
	url: string
}

export type SaveAqcChannelFromReferrerOptions = Omit<
	SaveAcqChannelOptions<AcqSourceSearch | AcqSourceReferrer>,
	'source' | 'data'
>

const isSearchDomain = (
	referrer: string,
	name: AcqSearchData['engine'],
	domains: string[],
) => {
	if (
		domains.some(
			(domain) =>
				referrer.startsWith(`https://${domain}/`) ||
				referrer.startsWith(`https://www.${domain}/`),
		)
	) {
		return name
	}

	return undefined
}

export const saveAqcChannelFromReferrer = (
	referrer: string,
	options: SaveAqcChannelFromReferrerOptions,
) => {
	if (
		referrer.includes(import.meta.env.PUBLIC_DASHBOARD_URL) ||
		referrer.includes(import.meta.env.PUBLIC_SITE_URL) ||
		(!import.meta.env.SSR && referrer.includes(window.location.hostname))
	) {
		return
	}

	const organicSearch =
		isSearchDomain(referrer, 'google', googleSearchDomains) ||
		isSearchDomain(referrer, 'bing', bingSearchDomains)

	if (organicSearch) {
		const existingOrganicSearch = getAcqChannel('organic-search')
		if (existingOrganicSearch) {
			return
		}

		saveAcqChannel({
			...options,
			source: 'organic-search',
			data: {
				engine: organicSearch,
			},
		} as SaveAcqChannelOptions<AcqSourceSearch>)

		return
	}

	const existingReferrer = getAcqChannel('referrer')
	if (existingReferrer) {
		return
	}

	saveAcqChannel({
		...options,
		source: 'referrer',
		data: {
			url: referrer,
		},
	} satisfies SaveAcqChannelOptions<AcqSourceReferrer>)
}
