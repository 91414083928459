export const initIntercomButton = () => {
	const intercomButton = document.querySelector<HTMLButtonElement>(
		'[data-intercom-open="true"]',
	)

	if (intercomButton) {
		intercomButton.toggleAttribute('disabled')

		const originalText = intercomButton.textContent

		//TODO Cannot use textContent for translations in the future
		intercomButton.textContent = 'Loading...'

		intercomButton.addEventListener('click', (event: Event) => {
			event.preventDefault()

			Intercom('show')
		})

		window.addEventListener('script-loaded', (event) => {
			const customEvent = event as CustomEvent<{ id: string }>

			if (customEvent.detail.id === 'intercom-snippet') {
				intercomButton.textContent = originalText

				intercomButton.removeAttribute('disabled')
			}
		})
	}
}
