export const safeNumber = (value: unknown, defaultValue = 1) => {
	if (value === undefined || value === null) return defaultValue

	const parsed = Number(value)

	return Number.isFinite(parsed) ? parsed : defaultValue
}

export interface PromiseResolvers<T> {
	resolve: (value: PromiseLike<T> | T) => void
	reject: (reason: unknown) => void
	promise: Promise<T>
}

export const promiseWithResolvers = <T = void>(): PromiseResolvers<T> => {
	let resolve!: (value: PromiseLike<T> | T) => void
	let reject!: (reason: unknown) => void

	const promise = new Promise<T>((_resolve, _reject) => {
		resolve = _resolve
		reject = _reject
	})

	return { resolve, reject, promise }
}

export const getBrowserType = () => {
	const test = (regexp: RegExp) => regexp.test(navigator.userAgent)

	if (test(/qqbrowser/i)) {
		return 'qqbrowser'
	}

	if (test(/baidubrowser/i)) {
		return 'baidubrowser'
	}

	if (test(/samsungbrowser/i)) {
		return 'samsung'
	}

	if (test(/opr\//i)) {
		return 'opera'
	}
	if (test(/edg/i)) {
		return 'edge'
	}
	if (test(/chrome|chromium|crios/i)) {
		return 'chrome'
	}
	if (test(/firefox|fxios/i)) {
		return 'firefox'
	}
	if (test(/safari/i)) {
		return 'safari'
	}

	return 'unknown'
}
