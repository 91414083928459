export const generateUUID = (): string => {
	if (globalThis.crypto?.randomUUID) {
		return globalThis.crypto.randomUUID()
	}

	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
		const r = (Math.random() * 16) | 0
		// biome-ignore lint/suspicious/noDoubleEquals: polyfill
		const v = c == 'x' ? r : (r & 0x3) | 0x8

		return v.toString(16)
	})
}
