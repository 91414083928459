import Cookies from 'js-cookie'
import { safeJsonParse } from './../utils/safe-json-parse'
import type {
	AcqReferralData,
	AcqSourceImpact,
	AcqSourceReferral,
} from './referral'
import type {
	AcqReferrerData,
	AcqSearchData,
	AcqSourceReferrer,
	AcqSourceSearch,
} from './referrer'

export type AcqSource =
	| 'referrer'
	| 'facebook'
	| 'adwords'
	| 'baidu'
	| 'direct'
	| AcqSourceSearch
	| AcqSourceReferrer
	| AcqSourceReferral
	| AcqSourceImpact

interface UtmData {
	utm_source?: string
	utm_medium?: string
	utm_campaign?: string
	utm_id?: string
	utm_content?: string
	utm_term?: string
}

export type AcqChannelBase<S extends AcqSource, Data = unknown> = UtmData & {
	source: S
	/** Time when channel was saved */
	timestamp: number
	locale: 'en' | 'zh-CN'
} & (Data extends never ? never : { data: Data })

type AcqChannelReferralImpact = AcqChannelBase<
	AcqSourceReferral | AcqSourceImpact,
	AcqReferralData
>
type AcqChannelSearch = AcqChannelBase<AcqSourceSearch, AcqSearchData>
type AcqChannelReferrer = AcqChannelBase<AcqSourceReferrer, AcqReferrerData>

export type AcqChannel<S extends AcqSource = AcqSource> =
	| AcqChannelBase<S>
	| AcqChannelReferralImpact
	| AcqChannelSearch
	| AcqChannelReferrer

const getKey = (source: AcqSource) => `mars-acq-channel__${source}` as const

const getUtmData = (query: string): UtmData => {
	const url = new URLSearchParams(query)

	const get = (key: string) => url.get(key) ?? undefined

	return {
		utm_source: get('utm_source'),
		utm_medium: get('utm_medium'),
		utm_campaign: get('utm_campaign'),
		utm_id: get('utm_id'),
		utm_content: get('utm_content'),
		utm_term: get('utm_term'),
	}
}

export interface SaveAcqChannelOptions<S extends AcqSource> {
	source: S
	locale: AcqChannel<S>['locale']
	data?: AcqChannel<S>['data']
	timestamp: number
	/** Needed in order to automatically extract utm params */
	query: string
}

export const saveAcqChannel = <S extends AcqSource>(
	options: SaveAcqChannelOptions<S>,
) => {
	const { source } = options

	const utmData = getUtmData(options.query)

	const data = {
		...utmData,
		timestamp: options.timestamp,
		source: options.source,
		locale: options.locale,
		data: options.data,
	} satisfies AcqChannel<S>

	if (import.meta.env.DEV) {
		console.info('Saving acquisition channel', source, data)
	}

	const expires =
		data.source === 'referral' || data.source === 'impact' ? 60 : 14

	Cookies.set(getKey(source), JSON.stringify(data), {
		expires,
		sameSite: 'strict',
		secure: true,
		domain: import.meta.env.PUBLIC_TOP_LEVEL_DOMAIN
			? `.${import.meta.env.PUBLIC_TOP_LEVEL_DOMAIN}`
			: undefined,
	})
}

export const getAcqChannel = <S extends AcqSource>(source: S) => {
	const data = Cookies.get(getKey(source))

	if (!data) {
		return null
	}

	return safeJsonParse<AcqChannel<S>>(data)
}

type Permutations<T, U = T> = [T] extends [never]
	? []
	: T extends T
		? [T, ...Permutations<Exclude<U, T>>]
		: never

export const getAllSavedAcqChannels = () => {
	// Direct source is never saved
	const sources: Permutations<Exclude<AcqSource, 'direct'>> = [
		'adwords',
		'facebook',
		'baidu',
		'impact',
		'referral',
		'referrer',
		'organic-search',
	]

	return sources
		.map((source) => getAcqChannel(source))
		.filter(Boolean) as AcqChannel[]
}

export const createDirectAcqChannel = (locale: 'en' | 'zh-CN') =>
	({
		source: 'direct',
		data: undefined,
		locale,
		timestamp: Date.now(),
	}) satisfies AcqChannel<'direct'>
